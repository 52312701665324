
import {
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalTop,
  mdiArrowAll,
  mdiCog,
  mdiPen,
  mdiPlus,
  mdiMinus,
  mdiTrashCan,
  mdiUpload,
} from "@mdi/js";
import { Vue, Component, Ref, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import editable from "@/components/helpers/editable.vue";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import { FileDataSource } from "@/data/Object/FileDataSource";
import { ListDataSource } from "@/data/List/ListDataSource";
import { required } from "@/cms-services/consts";

export enum layerTypes {
  Image,
  Date,
  String,
}

@Component({
  components: {
    draggable,
    editable,
    DataEditorDialog,
    FileUploaderDialog,
  },
})
export default class CertificateTemplate extends Vue {
  @Ref("canvas") canvas!: HTMLDivElement;
  @Ref("canvasParent") canvasParent!: HTMLDivElement;
  @Ref("transformer") transformer!: any;
  @Ref("dataEditor") dataEditor!: DataEditorDialog;
  @Prop() dataSource!: ObjectDataSource;
  @Prop({ default: 1200 }) documentWidth!: number;
  fileUploaderVisible: boolean = false;
  currentLayer: any = null;
  enabledAnchors: any = null;
  anchorSize: number = 5;
  rotateEnabled: boolean = true;
  icons: any = {
    arrowAll: mdiArrowAll,
    plus: mdiPlus,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    alignLeft: mdiAlignHorizontalLeft,
    alignCenter: mdiAlignHorizontalCenter,
    alignRight: mdiAlignHorizontalRight,
    alignTop: mdiAlignVerticalTop,
    alignMiddle: mdiAlignVerticalCenter,
    alignBottom: mdiAlignVerticalBottom,
    minus: mdiMinus,
  };
  contextMenu: any = {
    visible: false,
    x: null,
    y: null,
    shapeName: null,
  };
  loaded: boolean = false;
  sellectedItemName: any = "";
  configKonva = {};
  template: any = null;
  templateWidth: number = 0;
  templateHeight: number = 0;
  fileDataSource: FileDataSource = new FileDataSource({
    className: "attachedfile",
    id: 0,
    config: {},
  });

  templateLayer: ListDataSource = new ListDataSource({
    className: "CertificateTemplateLayer",
    config: {
      pageIndex: 1,
      pageSize: 100,
      orderFieldName: "Priority",
      orderFieldDirection: "ASC",
      filter: JSON.stringify([
        {
          fieldName: "certificateTemplateId",
          fieldValue: this.dataSource.id,
        },
      ]),
    },
  });
  sourceCahce: any = false;
  fieldNames: any = [
    {
      caption: "ФИО Слушателя",
      value: "FullName",
      sourceId: 1,
    },
    {
      caption: "Фамилия",
      value: "LastName",
      sourceId: 1,
    },
    {
      caption: "Имя",
      value: "FirstName",
      sourceId: 1,
    },
    {
      caption: "Отчество",
      value: "Patronymic",
      sourceId: 1,
    },
    {
      caption: "Имя и отчество",
      value: "FirstNamePatronymic",
      sourceId: 1,
    },
    {
      caption: "Фамилия и имя",
      value: "LastNameFirstName",
      sourceId: 1,
    },
    {
      caption: "ФИО(анг.)",
      value: "FullNameInEnglish",
      sourceId: 1,
    },
    {
      caption: "Название 1",
      value: "Caption",
      sourceId: 2,
    },
    {
      caption: "Название 2",
      value: "Caption2",
      sourceId: 2,
    },
    {
      caption: "Дата",
      value: "Date",
      sourceId: 2,
    },
    {
      caption: "Печать",
      value: "Stamp.File",
      sourceId: 2,
    },

    {
      caption: "Страна",
      value: "Country.Name",
      sourceId: 2,
    },
    {
      caption: "Страна (Латыница)",
      value: "Country.NameLat",
      sourceId: 2,
    },
    {
      caption: "Город",
      value: "City.Name",
      sourceId: 2,
    },
    {
      caption: "Город (Латыница)",
      value: "City.NameLat",
      sourceId: 2,
    },
    {
      caption: "ФИО",
      value: "FullName",
      sourceId: 3,
    },
    {
      caption: "ФИО(анг.)",
      value: "FullNameLat",
      sourceId: 3,
    },
    {
      caption: "Подпись",
      value: "SignatureId",
      sourceId: 3,
    },
    {
      caption: "Должность",
      value: "JobPosition",
      sourceId: 3,
    },
    {
      caption: "Должность(анг.)",
      value: "JobPositionLat",
      sourceId: 3,
    },
    {
      caption: "Дата",
      value: "CreateDate",
      sourceId: 4,
    },
    {
      caption: "Город",
      value: "City.Name",
      sourceId: 4,
    },
  ];

  sources: any = [
    {
      caption: "Профиль пользователя",
      value: "UserProfile",
      id: 1,
    },
    {
      caption: "Сертификат",
      value: "EventCertificate",
      id: 2,
    },
    {
      caption: "Лекторы",
      value: "Lector",
      id: 3,
    },
    {
      caption: "Событие",
      value: "Event",
      id: 4,
    },
  ];

  @Watch("dataSource.model", { deep: true })
  onChangeOriented(model) {
    const value = model.documentOrientation;
    if (value !== 1 && value !== 0) return;
    this.configKonva =
      value == 0
        ? {
            width: 1200,
            height: 848,
          }
        : {
            width: 848,
            height: 1200,
          };
  }

  $message: any;

  get canvasWidth() {
    return this.dataSource.model?.documentOrientation == 0 ? 1200 : 848;
  }

  get canvasHeight() {
    return this.dataSource.model?.documentOrientation == 0 ? 848 : 1200;
  }

  actions: any = {
    initFile: (base64: string, file: any) => {
      const formData = new FormData();
      formData.append("file", file, file.name);
      return {
        fileName: file.name,
        fileSize: file.size,
        base64,
        formData,
      };
    },
    getFontColors: () => [
      {
        caption: "Красный",
        color: "#f00",
      },
      {
        caption: "Синий",
        color: "#00f",
      },
      {
        caption: "Зелнный",
        color: "#0f0",
      },
      {
        caption: "Чрный",
        color: "#000",
      },
      {
        caption: "Белый",
        color: "#fff",
      },
    ],
    getTypes: () => [
      {
        caption: "Текст",
        id: "text",
      },
      {
        caption: "Изображение",
        id: "image",
      },
    ],
    getAligns: () => [
      {
        caption: "Влево",
        name: "left",
      },
      {
        caption: "По центру",
        name: "center",
      },
      {
        caption: "Вправо",
        name: "right",
      },
    ],
    getFontFamlies: () => [
      {
        caption: "Arial",
      },
      {
        caption: "Times New Roman",
      },
    ],
    getSources: () => this.sources,
    getFieldNames: () => this.fieldNames,
    filterFieldNames: (model: any) => {
      if (!model.sourceName) return [];
      const cb = (s: any) => s.value == model.sourceName;
      const sourceId = this.sources.find(cb).id;
      return this.fieldNames.filter((f: any) => f.sourceId === sourceId);
    },

    watchSoutceNameForAttrs: (model: any) => {
      return !model.sourceName
        ? {
            outlined: true,
            label: "Название поля *",
            disabled: true,
            persistentHint: true,
            hint: "Чтобы выбрать название поля выберите источник данных",
          }
        : {
            outlined: true,
            label: "Название поля *",
          };
    },
  };

  fields: any = [
    {
      name: "type",
      editor: "enum",
      attrs: {
        type: "text",
        outlined: true,
        label: "Что вы хотите добавить ?",
      },
      config: {
        getItems: "getTypes",
        valueKeyName: "id",
        labelKeyName: "caption",
        attrs: {
          small: true,
        },
      },
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Название слоя *",
      },
      name: "caption",
    },
    {
      editor: "bool",
      attrs: {
        type: "text",
        outlined: true,
        label: "Использовать поле обьекта",
      },
      name: "useModel",
      config: {
        type: "checkbox",
      },
    },
    {
      name: "sourceName",
      editor: "enum",
      attrs: {
        type: "text",
        outlined: true,
        label: "Источник данных ?",
        clearable: true,
      },
      grid: { cols: 6 },
      config: {
        getItems: "getSources",
        valueKeyName: "value",
        labelKeyName: "caption",
        type: "select",
      },
      validations: [required()],
      bindedFieldName: "useModel",
      bindedFieldValue: true,
    },
    {
      name: "fieldName",
      editor: "enum",
      attrs: {
        outlined: true,
        label: "Название поля *",
      },
      grid: { cols: 6 },
      config: {
        getItems: "getFieldNames",
        valueKeyName: "value",
        labelKeyName: "caption",
        type: "select",
      },
      validations: [required()],
      bindedFieldName: "useModel",
      bindedFieldValue: true,
      watchers: [
        {
          changeItems: "filterFieldNames",
          changeAttrs: "watchSoutceNameForAttrs",
        },
      ],
    },
    {
      editor: "number",
      bindedFieldName: "sourceName",
      bindedFieldValue: "Lector",
      attrs: {
        type: "text",
        outlined: true,
        label: "Индекс лектора",
      },
      name: "lectorIndex",
    },
    {
      name: "align",
      editor: "enum",
      bindedFieldName: "type",
      bindedFieldValue: "text",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выравнивание",
      },
      config: {
        getItems: "getAligns",
        valueKeyName: "name",
        labelKeyName: "caption",
        type: "radio",
      },
      validations: [required()],
      grid: { cols: 6 },
    },
    {
      name: "color",
      editor: "color-selector",
      bindedFieldName: "type",
      bindedFieldValue: "text",
      attrs: {
        type: "text",
        outlined: true,
        label: "Цвет шрифта",
      },
      config: {
        getItems: "getFontColors",
        valueKeyName: "color",
        labelKeyName: "caption",
        type: "select",
      },
      grid: { cols: 6 },
    },

    {
      editor: "number",
      attrs: {
        outlined: true,
        label: "Ось X *",
      },
      grid: { cols: 6 },
      name: "x",
    },
    {
      editor: "number",
      attrs: {
        type: "text",
        outlined: true,
        label: "Ось Y *",
      },
      grid: { cols: 6 },
      name: "y",
    },
    {
      editor: "number",
      attrs: {
        outlined: true,
        label: "Масштаб по X",
      },
      grid: { cols: 6 },
      name: "scaleX",
    },
    {
      editor: "number",
      attrs: {
        outlined: true,
        label: "Масштаб по Y",
      },
      grid: { cols: 6 },
      name: "scaleY",
    },
    {
      editor: "number",
      attrs: {
        outlined: true,
        label: "Ширина *",
      },
      grid: { cols: 6 },
      name: "width",
      validations: [required()],
    },
    {
      editor: "number",
      attrs: {
        type: "text",
        outlined: true,
        label: "Высота *",
      },
      grid: { cols: 6 },
      bindedFieldName: "type",
      bindedFieldValue: "image",
      name: "height",
    },
    {
      editor: "number",
      attrs: {
        type: "text",
        outlined: true,
        label: "Врашение",
      },
      grid: { cols: 6 },
      name: "rotation",
    },
    {
      editor: "file-uploader",
      name: "image",
      bindedFieldName: "type",
      bindedFieldValue: "image",
      config: {
        initFile: "initFile",
      },
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Текст *",
      },
      name: "text",
      bindedFieldName: "type",
      bindedFieldValue: "text",
      validations: [required()],
    },
    {
      name: "fontFamily",
      editor: "enum",
      bindedFieldName: "type",
      bindedFieldValue: "text",
      attrs: {
        type: "text",
        outlined: true,
        label: "Шрифт",
      },
      config: {
        getItems: "getFontFamlies",
        valueKeyName: "caption",
        labelKeyName: "caption",
        type: "select",
      },
      validations: [required()],
    },
    {
      editor: "number",
      attrs: {
        type: "text",
        outlined: true,
        label: "Размер шрифта",
      },
      bindedFieldName: "type",
      bindedFieldValue: "text",
      name: "fontSize",
      validations: [required()],
    },
  ];

  model: any = {
    type: "image",
    image: [],
    width: 300,
    height: 300,
    caption: "Новый слой",
    text: "Тестовый текст",
    fontSize: 30,
    fontFamily: "Arial",
    align: "center",
    color: "#000000",
    x: 400,
    y: 400,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
    fieldName: "",
    sourceName: "",
    useModel: false,
    layerType: null,
    lectorIndex: 0,
  };

  selectItem(layer) {
    this.currentLayer = layer;

    this.sellectedItemName = layer.name;

    const transformerNode = this.transformer.getNode();
    const stage = transformerNode.getStage();
    const selectedNode = stage.findOne("." + layer.name);
    if (selectedNode === transformerNode.node()) {
      return;
    }
    transformerNode.nodes(selectedNode ? [selectedNode] : []);
    transformerNode.moveToTop();
  }

  handleDragStart(e) {
    this.sellectedItemName = e.target.name();
  }

  async alignLeft() {
    await this.prepareAlign(() => (this.currentLayer.x = 0));
  }

  async alignCeter() {
    await this.prepareAlign(
      () =>
        (this.currentLayer.x =
          this.canvasWidth / 2 -
          (this.currentLayer.width * this.currentLayer.scaleX) / 2)
    );
  }
  async alignRight() {
    await this.prepareAlign(
      () =>
        (this.currentLayer.x =
          this.canvasWidth - this.currentLayer.width * this.currentLayer.scaleX)
    );
  }
  async alignTop() {
    await this.prepareAlign(() => (this.currentLayer.y = 0));
  }
  async alignMiddle() {
    const height = this.currentLayer.height ?? this.currentLayer.fontSize;
    await this.prepareAlign(
      () =>
        (this.currentLayer.y =
          this.canvasHeight / 2 - (height * this.currentLayer.scaleY) / 2)
    );
  }
  async alignBottom() {
    const height = this.currentLayer.height ?? this.currentLayer.fontSize;
    await this.prepareAlign(
      () =>
        (this.currentLayer.y =
          this.canvasHeight - height * this.currentLayer.scaleY)
    );
  }

  async prepareAlign(callBack: Function) {
    if (!this.currentLayer) return;
    callBack();
    this.templateLayer.className =
      this.currentLayer.type == "image"
        ? "CertificateTemplateImageLayer"
        : "CertificateTemplateTextLayer";
    await this.templateLayer.update(this.currentLayer);
  }

  async handleDragEnd(e, item) {
    item.x = e.target.x();
    item.y = e.target.y();
    item.rotation = e.target.rotation();
    item.scaleX = e.target.scaleX();
    item.scaleY = e.target.scaleY();
    switch (item.type) {
      case "image":
        this.templateLayer.className = "CertificateTemplateImageLayer";
        await this.templateLayer.update(item);
        break;
      case "text":
        this.templateLayer.className = "CertificateTemplateTextLayer";
        await this.templateLayer.update(item);
        break;
    }

    this.$message("Слой успешно обновлён");
  }

  async handleTransformEnd(e, item) {
    const transformerNode = this.transformer.getNode();

    switch (item.type) {
      case "image":
        {
          item.x = e.target.x();
          item.y = e.target.y();
          item.rotation = e.target.rotation();
          item.scaleX = e.target.scaleX();
          item.scaleY = e.target.scaleY();
          transformerNode.moveToTop();
          this.templateLayer.className = "CertificateTemplateImageLayer";
          await this.templateLayer.update(item);
        }
        break;

      case "text":
        {
          let scaleX = e.target.scaleX();
          let scaleY = e.target.scaleY();

          //console.log(item.width, e.target.width(), scaleX);
          e.target.setWidth(e.target.width() * scaleX);
          item.width = Math.round(e.target.width());
          //e.target.setHeight(item.height * scaleY);
          e.target.scale({ x: 1, y: 1 });
          e.target.scale({ x: 1, y: 1 });
          item.x = e.target.x();
          item.y = e.target.y();
          item.rotation = e.target.rotation();
          item.scaleX = 1; //e.target.scaleX();
          item.scaleY = 1; //e.target.scaleY();

          //item.width = item.width * e.target.scaleX();
          //item.height = item.width * e.target.scaleY();
          transformerNode.moveToTop();
          this.templateLayer.className = "CertificateTemplateTextLayer";
          //console.log(e);
          await this.templateLayer.update(item);
        }
        break;
    }

    this.$message("Слой успешно обновлён");
  }

  handleStageMouseDown(e) {
    if (e.target === e.target.getStage()) {
      this.sellectedItemName = "";
      this.updateTransformer();
      return;
    }

    if (e.target.getParent().className === "Transformer") {
      return;
    }
    const name = e.target.name();
    const rect = this.templateLayer.items.find((r) => r.name === name);

    this.sellectedItemName = rect ? name : "";
    this.currentLayer = this.templateLayer.items.find(
      (l) => l.name === this.sellectedItemName
    );

    switch (this.currentLayer.type) {
      case "text":
        this.rotateEnabled = false;
        this.enabledAnchors = ["middle-left", "middle-right"];
        break;
      case "image":
        this.rotateEnabled = true;
        this.enabledAnchors = null;
        break;
    }
    this.updateTransformer();
  }

  updateTransformer() {
    const transformerNode = this.transformer.getNode();

    const stage = transformerNode.getStage();
    const selectedNode = stage.findOne("." + this.sellectedItemName);
    if (selectedNode === transformerNode.node()) {
      return;
    }
    transformerNode.nodes(selectedNode ? [selectedNode] : []);
    transformerNode.moveToTop();
  }

  async changePriorities() {
    this.templateLayer.className = "CertificateTemplateLayer";
    await this.templateLayer.changePriority();
  }

  async add() {
    const model: any = await this.dataEditor.update(this.model);
    if (!model) return;
    const commonConfig = {
      certificateTemplateId: this.dataSource.id,
      type: model.type,
      x: model.x ?? 100,
      y: model.y ?? 100,
      caption: model.caption,
      rotation: 0,
      scaleX: 1,
      scaleY: 1,
      useModel: model.useModel,
      sourceName: model.sourceName,
      fieldName: model.fieldName,
      width: model.width,
      lectorIndex: model.lectorIndex,
    };
    switch (model.type) {
      case "text":
        this.templateLayer.className = "CertificateTemplateTextLayer";

        await this.templateLayer.add({
          ...commonConfig,
          text: model.text,
          color: model.color,
          align: model.align,
          fontSize: model.fontSize,
          fontFamily: model.fontFamily,
          fill: "black",
        });
        break;

      case "image":
        this.templateLayer.className = "CertificateTemplateImageLayer";
        const data = await this.templateLayer.add({
          ...commonConfig,
          height: model.height,
        });
        const modelWithFile = await this.templateLayer.uploadFile(
          data.id,
          model.image[0].formData
        );
        const item = this.templateLayer.items.find((x) => x.id === data.id);
        Object.assign(item, modelWithFile.model);
        const image = new Image();
        image.src = model.image[0].base64;
        image.onload = () => {
          this.$set(item, "image", image);
        };

        break;
    }

    this.$message("Слой успешно добавлен");
  }

  async edit(layer) {
    const model: any = await this.dataEditor.update({ ...layer, image: [] });
    if (!model) return;
    layer.caption = model.caption;
    layer.x = model.x;
    layer.y = model.y;
    layer.type = model.type;
    layer.rotation = model.rotation;
    layer.fieldName = model.fieldName;
    layer.width = model.width;
    layer.useModel = model.useModel;
    layer.sourceName = model.sourceName;
    layer.lectorIndex = model.lectorIndex;
    switch (model.type) {
      case "text":
        this.templateLayer.className = "CertificateTemplateTextLayer";
        layer.align = model.align;
        layer.color = model.color;
        layer.fontFamily = model.fontFamily;
        layer.fontSize = model.fontSize;
        layer.text = model.text;
        await this.templateLayer.update(layer);
        break;

      case "image":
        this.templateLayer.className = "CertificateTemplateImageLayer";
        layer.scaleX = model.scaleX;
        layer.scaleY = model.scaleY;
        layer.height = model.height;
        await this.templateLayer.update(layer);
        if (!(model.image?.length > 0)) return;
        await this.templateLayer.uploadFile(layer.id, model.image[0].formData);
        const index = this.templateLayer.items.findIndex(
          (x) => x.id === layer.id
        );
        const image = new Image();
        image.src = model.image[0].base64;
        image.onload = () => {
          this.templateLayer.items[index].image = image;
        };
        break;
    }

    this.$message("Слой успешно обновлён");
  }

  async created() {
    window.addEventListener("resize", this.onWindowResize);
    await this.templateLayer.get();

    const layers: any = [];
    for (const layer of this.templateLayer.items) {
      switch (layer.type) {
        case "text":
          layers.push(layer);
          break;

        case "image":
          const image = new Image();
          if (layer.fileId) {
            image.src = `/api/v1/manage/attachedfile/${layer.fileId}/file`;
            image.onload = () => {
              layers.push({
                ...layer,
                image,
              });
            };
          } else {
            layers.push({
              ...layer,
              image,
            });
          }
          break;
      }
    }
    this.templateLayer.items = layers.sort((a, b) => a.priority - b.priority);

    await this.$nextTick();
    this.onWindowResize();
    this.configKonva = {
      width: this.canvas.offsetWidth,
      height: this.canvas.offsetHeight,
    };
  }

  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize() {
    this.canvas.style.transform = `scale( ${
      this.canvasParent.offsetWidth / 1200
    })`;
  }

  async uploadTemplate(event: any) {
    try {
      const data = await this.dataSource.uploadFile(event);
      // this.template = data?.fileContents
      //   ? `data:${data.contentType};base64,${data?.fileContents}`
      //   : null;
      this.model.templateId = data.id;
      this.$message("Шаблон успешно загружен");
      this.fileUploaderVisible = false;
    } catch (err) {
      throw err;
    }
  }

  async clearTemplate(event: any) {
    try {
      await this.dataSource.clearFile(event);

      this.model.template = null;
      this.$message("Шаблон успешно очищен");
    } catch (err) {
      throw err;
    }
  }

  async remove(layer: any) {
    await this.templateLayer.remove(layer.id);
    this.$message("Слой успешно удалён");
  }
}
